import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    ImageContainer
} from 'Component/Image/Image.container';

import ImageWebp from './ImageWebp.component';

/** @namespace ZnetPwa/Component/ImageWebp/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    placeholderImageUrl: state.ConfigReducer.placeholder_image,
    isCloudinaryEnabled: state.ConfigReducer.cloudinary_enabled
});

/** @namespace ZnetPwa/Component/ImageWebp/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace ZnetPwa/Component/ImageWebp/Container/ImageWebpContainer */
export class ImageWebpContainer extends ImageContainer {
    static propTypes = {
        ...this.propTypes,
        isBlog: PropTypes.bool,
        placeholderImageUrl: PropTypes.string.isRequired,
        isCloudinaryEnabled: PropTypes.bool.isRequired
    };

    static defaultProps = {
        ...this.defaultProps,
        isBlog: false
    };

    containerProps = () => {
        const {
            isBlog,
            placeholderImageUrl,
            alt,
            src
        } = this.props;
        const updatedAlt = src.includes('placeholder') || src.includes('productundefined')
            ? 'Product image placeholder'
            : alt;

        return {
            isBlog,
            alt: updatedAlt,
            placeholderImageUrl,
            style: this._getStyle(),
            wrapperSize: this._getWrapperSize()
        };
    };

    _getWrapperSize() {
        const { isBlog } = this.props;

        if (isBlog) {
            return {};
        }

        const size = this._getCorrectSize();
        const { height, width } = size;

        if (height.slice(-1) === '%' && width.slice(-1) === '%') {
            return {};
        }

        return height.slice(-1) !== '%'
            ? { paddingBottom: height }
            : { paddingBottom: width };
    }

    render() {
        return (
            <ImageWebp
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageWebpContainer);
